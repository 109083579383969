import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Image, Row, Col} from 'react-bootstrap';

// Define the static CloudFront URL for the icon
const YOUTUBE_ICON_URL = 'https://d13e30dasa2fhv.cloudfront.net/oral_hyglene_supplier/youtube60x60.jpg';
const VIDEO_BASE = `https://${process.env.REACT_APP_HOST_DOMAIN}/dentalmiscvideos/videos/`;


export default function DentalKnowledgeList(props) {
  const videoUrl = `${VIDEO_BASE}${encodeURIComponent(props.title)}`;


  return (
    <li key={props.id} className='content-list-element'>
      <Row style={{height: '100%'}}>
        <Col xs={3} style={{maxHeight: '100%', maxWidth: '100%'}}>
          <Image src={props.image} />
        </Col>
        <Col xs={5}>
          <h4 style={{color: '#474747', margin: '0'}}>
            <Link
              to={`./${props.id}`}
              style={{textDecoration: 'none', color: '#474747'}}
            >
              {props.title}
            </Link>
          </h4>
        </Col>
        <Col xs={4} style={{alignItems: 'left'}}>
          <a
            href={videoUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={YOUTUBE_ICON_URL}
              alt='YouTube'
              style={{width: '24px', height: '24px'}}
            />
          </a>
        </Col>
      </Row>
    </li>
  );
}

DentalKnowledgeList.propTypes = {
  id: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
};
